import { type ReactNode, memo, useCallback, useMemo, useEffect } from 'react';
import { TextField, MenuItem, type SelectProps as MuiSelectProps, type MenuProps } from '@mui/material';
import { Controller, useFormContext } from 'FrontRoyalReactHookForm';
import { type AnyObject } from '@Types';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import clsx from 'clsx';

type BaseProps<T> = {
    options: T[];
    name: string;
    optionLabel?: (val: T) => ReactNode;
    optionValue?: (val: T) => string | number;
    /** @param val Current Value. This will be the result of optionValue if defined */
    renderValue?: (val: unknown) => ReactNode;
    loading?: boolean;
    size?: string;
    wrapSelectedOptions?: boolean;
    variant?: 'standard' | 'outlined' | 'filled';
};

type SelectProps<T> = Omit<MuiSelectProps<T>, 'name' | 'renderValue' | 'onChange'> & BaseProps<T>;

const isObject = (possibleObject: unknown): possibleObject is AnyObject => typeof possibleObject === 'object';

const defaultMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

function displayFormatter<T>(opt: T, optionLabel?: SelectProps<T>['optionLabel']) {
    if (optionLabel) return optionLabel(opt) || '';
    if (typeof opt === 'string') return opt;
    if (isObject(opt)) {
        if ('label' in opt) return opt.label as string;
    }

    return '';
}

function valueFormatter<T>(opt: T, optionValue?: SelectProps<T>['optionValue']) {
    if (optionValue) return optionValue(opt) || '';
    if (typeof opt === 'string') return opt;
    if (isObject(opt)) {
        if ('value' in opt) return opt.value as string;
    }

    return '';
}

type SelectOptionType = string | number | string[] | undefined;

function SelectComponent<T>({
    options,
    optionLabel,
    optionValue,
    MenuProps = defaultMenuProps,
    multiple,
    label,
    name,
    fullWidth,
    required,
    disabled,
    size = 'medium',
    loading = false,
    wrapSelectedOptions = false,
    variant = 'outlined',
    ...rest
}: SelectProps<T>) {
    const { control, setFieldValue, watch, getFieldState } = useFormContext<{ [name: string]: SelectOptionType }>();
    const defaultValue = useMemo(() => (multiple ? [] : ''), [multiple]);
    const fieldValue = watch(name);
    const valFormatter = useCallback((opt: T) => valueFormatter(opt, optionValue), [optionValue]);
    const labelFormatter = useCallback((opt: T) => displayFormatter(opt, optionLabel), [optionLabel]);

    const { isTouched, error } = getFieldState(name);

    const isValidOption = useCallback(
        (val: SelectOptionType) => {
            if (multiple && Array.isArray(val)) {
                return val.every(value => !!options.find(opt => valFormatter(opt) === value));
            }
            return !!options.find(opt => valFormatter(opt) === val);
        },
        [multiple, options, valFormatter],
    );

    useEffect(() => {
        const isEmpty = Array.isArray(fieldValue) && fieldValue.length === 0;

        if (fieldValue && !isEmpty && !isValidOption(fieldValue)) {
            setFieldValue(name, defaultValue);
        }
    }, [defaultValue, fieldValue, isValidOption, setFieldValue, name]);

    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            name={name}
            render={({ field }) => (
                <TextField
                    SelectProps={{
                        error: isTouched && !!error,
                        ...field,
                        ...rest,
                        MenuProps,
                        multiple,
                        value: isValidOption(field.value) ? field.value : defaultValue,
                        'aria-disabled': disabled,
                        disabled,
                        inputProps: { className: clsx({ 'whitespace-normal': wrapSelectedOptions }) },
                    }}
                    fullWidth={fullWidth}
                    required={required}
                    label={label}
                    disabled={disabled}
                    size={size}
                    select
                    variant={variant}
                >
                    {loading ? (
                        <MenuItem>
                            <FrontRoyalSpinner className="no-delay no-top-margin" color="blue" />
                        </MenuItem>
                    ) : (
                        options?.map(option => (
                            <MenuItem key={valFormatter(option)} value={valFormatter(option)}>
                                {labelFormatter(option)}
                            </MenuItem>
                        ))
                    )}
                </TextField>
            )}
        />
    );
}

export const Select = memo(SelectComponent) as typeof SelectComponent;

export default Select;
